import { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import downArrow from "../images/down-arrow.svg";
import { useUserAuth } from "../store/auth-context";

const Header = () => {
	const { logOut, user } = useUserAuth();
	const navigate = useNavigate();

	const userMenu = useRef(null);

	const [showMenu, setShowMenu] = useState(false);

	const showMenuHandler = () => {
		setShowMenu(true);
	}

	const onLogout = () => {
		logOut();
		navigate("/");
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (userMenu.current && !userMenu.current.contains(event.target)) {
				setShowMenu(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [userMenu])

	return (
		<div className="pre-header container inner-page">
			<Link to="/dashboard" className="logo">
				MetaDesk
			</Link>
			<nav>
				<ul>
					<li>
						<Link to="/faqs">FAQs &amp; Help</Link>
					</li>
					<li className="user-details">
						<div className="user-details-top" onClick={showMenuHandler}>
							<span className="user-img">
								{user.displayName &&
									user.displayName
										.match(/(\b\S)?/g)
										.join("")
										.match(/(^\S|\S$)?/g)
										.join("")
										.toUpperCase()}
							</span>
							<span>
								<img src={downArrow} alt="" />
							</span>
						</div>
						{showMenu && (
							<div className="user-details-btm" ref={userMenu}>
								<ul>
									<li>
										<Link to="/profile">Account Details</Link>
									</li>
									<li>
										<span className="color-change" onClick={onLogout}>
											Logout
										</span>
									</li>
								</ul>
							</div>
						)}
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Header;

import { useUserAuth } from "../store/auth-context";
import hand from "../images/hand.png";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";

const Welcome = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  const [pageLoaded, setPageLoaded] = useState("");
  const [username, setUsername] = useState(
    localStorage.getItem("signup_username")
  );

  useEffect(() => {
    const intervalId = setInterval(() => navigate("/dashboard"), 5000);

    if (Object.keys(user).length) {
      if (user.displayName) {
        setUsername(user.displayName);
      }
      setPageLoaded(true);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [navigate, user]);

  return (
    <div className="welcome">
      <img src={hand} alt="" />
      <div>
        {pageLoaded ? (
          <Fragment>
            Welcome to MetaDesk, <span>{pageLoaded && username + "!"}</span>
          </Fragment>
        ) : (
          <Bars color="#000" height={80} width={80} />
        )}
      </div>
    </div>
  );
};

export default Welcome;

const CurrencyChar = (country) => {
	let char = "";
	if (country === "UK") char = "£";
	if (country === "IN") char = "₹";
	if (country === "DE") char = "€";
	if (country === "ES") char = "€";
	return char;
}

export default CurrencyChar;

import { Fragment, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import { useUserAuth } from "../store/auth-context";
import ButtonMailto from "../components/ui/ButtonMailTo";
import { singleUserMachineConfig } from "../constants/machines";
import { countryCurrencySymbol } from "../constants/currency";
import { Bars } from "react-loader-spinner";
import emojiHand from "../images/emoji-raising-hands-apple.png";
import { userRoles } from "../constants/userRoles";
import { formatDate } from "../helper";

const PaymentSuccess = () => {
  document.body.classList.add("grey");
  const orderData = JSON.parse(localStorage.getItem("order_data"));

  const { user, userRole } = useUserAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [orderDetails, setOrderDetails] = useState("");
  const [pageLoaded, setPageLoaded] = useState("");

  useEffect(() => {
    const orderId = searchParams.get("orderId");
    if (orderId && Object.keys(user).length) {
      fetch(`${process.env.REACT_APP_API_URL}/user/order/status`, {
        method: "POST",
        body: JSON.stringify({
          orderId: orderId,
        }),
        headers: new Headers({
          idtoken: user.accessToken,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setOrderDetails(data.pack);
          setPageLoaded(true);
        })
        .catch(() => {
          setPageLoaded(true);
        });
    }
  }, [user]);

  return (
    <Fragment>
      <Header />

      <div className="container">
        <div className="page-wrap">
          <main>
            <div className="content-wrap">
              <div className="payment-status">
                <h1>
                  Payment Successful! <img src={emojiHand} alt="" />
                </h1>
                <p>Congratulations, your Cloud Machine is ready for setup!</p>
                <p>
                  If you have any questions, email us anytime on{" "}
                  <ButtonMailto
                    label="help@metadesk.run"
                    mailto="mailto:help@metadesk.run"
                  />
                </p>
                <Link to="/dashboard" className="btn btn-light">
                  Go to Dashboard
                </Link>
              </div>
              <div className="payment-summary">
                <h2>Payment Summary</h2>
                {pageLoaded ? (
                  <ul>
                    <li>
                      <span>Amount</span>
                      <span>
                        {countryCurrencySymbol[orderDetails.region]}{" "}
                        {orderDetails.price}
                      </span>
                    </li>
                    <li>
                      <span>Payment Method</span>
                      <span>{orderDetails.paymentMethod}</span>
                    </li>
                    <li>
                      <span>Payment Status</span>
                      <span>Successful</span>
                    </li>
                  </ul>
                ) : (
                  <div className="text-center">
                    <Bars color="#C4F34E" height={60} width={60} />
                  </div>
                )}
              </div>
            </div>
          </main>

          <aside className="siderbar">
            <h3>Your Cloud Machine</h3>
            {pageLoaded ? (
              <Fragment>
                <ul className="selected-values first">
                  <li>
                    <span className="col">Country</span>
                    <span className="col">{orderDetails.region}</span>
                  </li>
                </ul>
                <ul className="selected-values">
                  {singleUserMachineConfig.map((row, i) => (
                    <li className="row" key={i}>
                      <span className="col">{row.label}</span>
                      <span className="col">{row.value}</span>
                    </li>
                  ))}
                </ul>
                {userRole == userRoles.instructor && (
                  <ul className="selected-values">
                    <li>
                      <span className="col">Concurrent Students</span>
                      <span className="col">{orderData.studentsCount}</span>
                    </li>
                    <li>
                      <span className="col">Course Dates</span>
                      <span className="col">
                        {formatDate(orderData.startDate)} -{" "}
                        {formatDate(orderData.endDate)}{" "}
                      </span>
                    </li>
                  </ul>
                )}
                <ul className="selected-values">
                  <li>
                    <span className="col">Runtime</span>
                    <span className="col">{orderDetails.hours} hours</span>
                  </li>
                </ul>
              </Fragment>
            ) : (
              <div className="text-center">
                <Bars color="#C4F34E" height={60} width={60} />
              </div>
            )}
          </aside>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentSuccess;

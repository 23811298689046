import { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";

//https://github.com/Hacker0x01/react-datepicker

import calendarIcon from "../../images/icon-calendar.svg";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../helper";

const SetCourseDetails = (props) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [dateError, setDateError] = useState("");
  const [studentCountError, setStudentCountError] = useState("");
  const [componentClosed, setComponentClosed] = useState("");

  useEffect(() => {
    if (!props.platformConfirmation) {
      setComponentClosed(false);
    }
  }, [props.selectedPackage, props.platformConfirmation]);

  const handleConfirmation = () => {
    let error = 0;
    if (!props.studentsCount) {
      error++;
      setStudentCountError("This field is required.");
    } else if (props.studentsCount > 40 || props.studentsCount < 1) {
      error++;
      setStudentCountError("This field can have values between 1 and 40.");
    }

    if (!props.startDate || !props.endDate) {
      error++;
      setDateError("This field is required.");
    }

    if (!error) {
      props.setCourseConfirmation(true);
      setComponentClosed(true);
    }
  };

  const handleChange = () => {
    setStudentCountError("");
    setDateError("");
    props.setCourseConfirmation(false);
    setComponentClosed(false);
  };

  return (
    <div className="setup-step">
      <span className="setup-num">{props.step}</span>
      <div className="setup-content">
        <h2>Set your Course Details</h2>

        {(!props.startDate ||
          !props.endDate ||
          !props.studentsCount ||
          !componentClosed) &&
        props.platformConfirmation ? (
          <Fragment>
            <div className="instructions big">
              <p>
                <strong>How many concurrent students will you have?</strong>
              </p>
              <p className="faded">
                i.e. How many students will need to use the Cloud Machines
                simultaneously, at max? We’ll provide exactly those many Cloud
                Machines.
              </p>
            </div>

            <div className="form-row">
              <input
                type="text"
                placeholder="Enter No. of Concurrent Students"
                name="students"
                value={props.studentsCount}
                onChange={(e) => {
                  setStudentCountError("");
                  props.setStudentsCount(e.target.value);
                }}
              />
              {studentCountError && (
                <div className="error">{studentCountError}</div>
              )}
            </div>

            <div className="instructions big">
              <p>
                <strong>When does the course start and end?</strong>
              </p>
              <p className="faded">
                This will define the dates &amp; duration of the Cloud Machines
                to remain active.
              </p>
            </div>

            <div className="form-row calendar">
              <DatePicker
                placeholderText="Select Start & End Dates"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                minDate={new Date()}
                onChange={(dates) => {
                  if (dates[0] && dates[1]) {
                    props.setStartDate(dates[0]);
                    props.setEndDate(dates[1]);
                  } else {
                    props.setStartDate("");
                    props.setEndDate("");
                  }
                  setDateError("");
                  setDateRange(dates);
                }}
              />
              <img
                src={calendarIcon}
                alt="calendar"
                className="icon-calendar"
              />
              {dateError && <div className="error">{dateError}</div>}
            </div>
          </Fragment>
        ) : (
          ""
        )}

        {props.startDate &&
          props.endDate &&
          props.studentsCount &&
          props.platformConfirmation &&
          componentClosed && (
            <div>
              {props.studentsCount} Concurrent Students,{" "}
              {formatDate(props.startDate)} - {formatDate(props.endDate)}{" "}
              <span onClick={handleChange} className="reopen">
                Change
              </span>
            </div>
          )}

        {!componentClosed && props.platformConfirmation && (
          <button
            className="btn btn-light"
            onClick={() => handleConfirmation()}
          >
            Confirm Details
          </button>
        )}
      </div>
    </div>
  );
};

export default SetCourseDetails;

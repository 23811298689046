import { useRef, useState } from "react";
import { useUserAuth } from "../../store/auth-context";
import { Bars } from "react-loader-spinner";
import { Fragment } from "react/cjs/react.production.min";

const FormForgotPassword = (props) => {
  const userEmail = useRef();

  const { resetPassword } = useUserAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [submissionInProgress, setSubmissionInProgress] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (userEmail.current.value) {
      setSubmissionInProgress(true);
      try {
        const result = await resetPassword(userEmail.current.value);
        setSubmissionInProgress(false);
        setSuccessMessage(
          "Please check your email. Click on the link there and you’ll be able to enter a new password."
        );
        setErrorMessage("");
      } catch (error) {
        setSuccessMessage("");
        setErrorMessage("There was an error in processing your request.");
        setSubmissionInProgress(false);
      }
    } else {
      setSuccessMessage("");
      setErrorMessage("Please enter your email.");
    }
  };

  return (
    <div>
      <h2>{successMessage === "" ? "Forgot Password" : "Resent Link Sent!"}</h2>
      {successMessage === "" && (
        <Fragment>
          <p>
            Happens to the best of us. Tell us your email address and we'll send
            you a link to reset your password.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="email"
                placeholder="Enter Your Email Address"
                name="Email"
                ref={userEmail}
                onChange={(e) => {
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </div>
            {errorMessage && <div className="error">{errorMessage}</div>}
            <div className="form-row">
              <button type="submit" className="btn btn-dark">
                {submissionInProgress ? (
                  <Bars color="#C4F34E" height={20} width={139} />
                ) : (
                  "Send Reset Link"
                )}
              </button>
            </div>
          </form>
        </Fragment>
      )}
      {successMessage !== "" && (
        <div className="success">
          <p>{successMessage}</p>
          <button className="btn btn-dark" onClick={props.hidePopup}>
            Got it
          </button>
        </div>
      )}
    </div>
  );
};

export default FormForgotPassword;

export const formatDate = (date) => {
  date = new Date(date);
  return (
    ("0" + date.getDate()).slice(-2) +
    " " +
    date.toLocaleString("default", { month: "short" }) +
    " '" +
    date.getFullYear().toString().substr(-2)
  );
};

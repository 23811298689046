import { useState, Fragment, useEffect } from "react";
import { IconUnity, IconUnreal } from "../Icons";

const SelectPlatform = ({
  step,
  platform,
  setPlatform,
  platforms,
  countryConfirmation,
  setPlatformConfirmation,
  setRuntimeConfirmation,
  setCourseConfirmation,
  isBusinessUser,
}) => {
  const [platformError, setPlatformError] = useState("");
  const [componentClosed, setComponentClosed] = useState("");

  useEffect(() => {
    if (!countryConfirmation) {
      setComponentClosed(false);
    }
  }, [countryConfirmation]);

  const handleConfirmation = () => {
    if (platform) {
      setPlatformConfirmation(true);
      setPlatformError("");
      setComponentClosed(true);
    } else {
      setPlatformError("Please select the platform");
    }
  };

  const handleChange = () => {
    setPlatformError("");
    setPlatformConfirmation(false);
    setComponentClosed(false);
    setRuntimeConfirmation(false);
    setCourseConfirmation(false);
  };

  return (
    <div className="setup-step">
      <span className="setup-num">{step}</span>
      <div className="setup-content">
        <h2>Choose your Development Platform</h2>

        {(!platform || !componentClosed) && countryConfirmation ? (
          <Fragment>
            <div className="machines-list">
              {platforms
                ? platforms.map((obj, i) => (
                    <div
                      key={i}
                      className={
                        platform == obj.name
                          ? "machine-block active"
                          : "machine-block"
                      }
                      onClick={(e) => {
                        if (!obj.disabled || isBusinessUser) {
                          setPlatform(obj.name);
                        }
                      }}
                      data-disabled={obj.disabled && !isBusinessUser}
                    >
                      {obj.icon}
                      <h3>{obj.name}</h3>
                      <ul>
                        <li>
                          <span>CPU</span>
                          <span>{obj.cpu}</span>
                        </li>
                        <li>
                          <span>CPU Cores</span>
                          <span>{obj.cpu_cores}</span>
                        </li>
                        <li>
                          <span>RAM</span>
                          <span>{obj.ram}</span>
                        </li>
                        <li>
                          <span>GPU</span>
                          <span>{obj.gpu}</span>
                        </li>
                        <li>
                          <span>GPU RAM</span>
                          <span>{obj.gpu_ram}</span>
                        </li>
                      </ul>
                    </div>
                  ))
                : ""}
            </div>
            {platformError ? <div className="error">{platformError}</div> : ""}
          </Fragment>
        ) : (
          ""
        )}

        {platform && componentClosed && countryConfirmation && (
          <div>
            {platform}{" "}
            <span onClick={handleChange} className="reopen">
              Change
            </span>
          </div>
        )}

        {!componentClosed && countryConfirmation && (
          <button
            className="btn btn-light"
            onClick={() => handleConfirmation()}
          >
            Confirm Platform
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectPlatform;

import { Fragment, useState } from "react";
import Header from "../components/Header";
import { useUserAuth } from "../store/auth-context";
import Modal from "../components/Modal";
import FormChangePassword from "../components/popups/FormChangePassword";
import FormForgotPassword from "../components/popups/FormForgotPassword";

const Profile = () => {
  document.body.classList.add("grey");

  const { user } = useUserAuth();
  const [changePass, setChangePass] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);

  const handleForgotPass = () => {
    setChangePass(false);
    setForgotPass(true);
  };

  return (
    <Fragment>
      <Header />

      <div className="container page-wrap">
        <main className="account-details">
          <div className="content-wrap">
            <h2>Account Details</h2>

            <form>
              <input type="text" value={user.displayName} disabled />
              <input type="email" value={user.email} disabled />
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => setChangePass(true)}
              >
                Change Password
              </button>
            </form>
          </div>
        </main>
      </div>

      {changePass && (
        <Modal hidePopup={() => setChangePass(false)}>
          <FormChangePassword
            handleForgotPass={handleForgotPass}
            hidePopup={() => setChangePass(false)}
          />
        </Modal>
      )}

      {forgotPass && (
        <Modal hidePopup={() => setForgotPass(false)}>
          <FormForgotPassword hidePopup={() => setForgotPass(false)} />
        </Modal>
      )}
    </Fragment>
  );
};

export default Profile;

import { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import { useUserAuth } from "../store/auth-context";
import { IconUnity, IconUnreal } from "../components/Icons";

import emojiVoltage from "../images/emoji-high-voltage-apple.png";

import SelectCountry from "../components/setup/step_country";
import SelectPlatform from "../components/setup/step_platform";
import SetCourseDetails from "../components/setup/step_course_details";
import SelectDuration from "../components/setup/step_duration";
import MakePayment from "../components/setup/step_payment";
import SidebarSummary from "../components/setup/sidebar_summary";
import { userRoles } from "../constants/userRoles";

const platforms = [
  {
    name: "Unreal Engine",
    cpu: "Intel Xeon E5-2670",
    cpu_cores: 4,
    ram: "16 GB",
    gpu: "Nvidia T4",
    gpu_ram: "16 GB",
    icon: <IconUnreal />,
    disabled: false,
  },
  {
    name: "Unity Engine",
    cpu: "Intel Xeon E5-2670",
    cpu_cores: 4,
    ram: "16 GB",
    gpu: "Nvidia T4",
    gpu_ram: "16 GB",
    icon: <IconUnity />,
    disabled: true,
  },
];

const SetupMachine = () => {
  document.body.classList.add("grey");
  const { user, userRole } = useUserAuth();

  const [country, setCountry] = useState("");
  const [platform, setPlatform] = useState("");
  const [hours, setHours] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [studentsCount, setStudentsCount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [countryConfirmation, setCountryConfirmation] = useState("");
  const [platformConfirmation, setPlatformConfirmation] = useState("");
  const [runtimeConfirmation, setRuntimeConfirmation] = useState("");
  const [courseConfirmation, setCourseConfirmation] = useState("");
  const [runtime, setRuntime] = useState("");
  const [amount, setAmount] = useState("");

  const QuestionClicked = (event) => {
    event.target.classList.toggle("open");
  };

  const makePayment = () => {
    if (country && platform && hours) {
      if (userRole != userRoles.instructor) {
        makeSingleUserPayment();
      } else {
        makeBusinessUserPayment();
      }
    }
  };

  const makeSingleUserPayment = () => {
    fetch(`${process.env.REACT_APP_API_URL}/user/wallet/recharge`, {
      method: "POST",
      body: JSON.stringify({
        packId: selectedPackage,
        success_url: window.location.origin + "/success",
        cancel_url: window.location.origin + "/failed",
      }),
      headers: new Headers({
        idtoken: user.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.url) {
          window.location.href = data.url;
          localStorage.setItem(
            "order_data",
            JSON.stringify({
              country,
              hours,
              amount,
              payment_url: data.url,
            })
          );
        }
      });
  };

  const makeBusinessUserPayment = () => {
    fetch(`${process.env.REACT_APP_API_URL}/pack/recharge`, {
      method: "POST",
      body: JSON.stringify({
        Platform: platform,
        VMs: studentsCount,
        TotalHours: hours,
        StartAt: startDate.getTime(),
        ExpiresAt: endDate.getTime(),
        Region: country,
        Price: amount,
        success_url: window.location.origin + "/success",
        cancel_url: window.location.origin + "/failed",
      }),
      headers: new Headers({
        idtoken: user.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.url) {
          window.location.href = data.url;
          localStorage.setItem(
            "order_data",
            JSON.stringify({
              country,
              hours,
              amount,
              studentsCount,
              startDate,
              endDate,
              payment_url: data.url,
            })
          );
        }
      });
  };

  return (
    <Fragment>
      <Header />

      <div className="container" data-val={userRole}>
        {userRole != userRoles.instructor && (
          <h1 className="page-title emoji-voltage">
            Let’s set up your Cloud Machine <img src={emojiVoltage} alt="" />
          </h1>
        )}
        {userRole == userRoles.instructor && (
          <h1 className="page-title emoji-voltage">
            Set up your company’s Cloud Machines
          </h1>
        )}
        <div className="page-wrap">
          <main>
            <div className="setup-wrap">
              <SelectCountry
                step={"1"}
                country={country}
                setCountry={setCountry}
                setCountryConfirmation={setCountryConfirmation}
                setPlatformConfirmation={setPlatformConfirmation}
                setCourseConfirmation={setCourseConfirmation}
                setRuntimeConfirmation={setRuntimeConfirmation}
                setAmount={setAmount}
              />
              <SelectPlatform
                step={"2"}
                platform={platform}
                setPlatform={setPlatform}
                platforms={platforms}
                countryConfirmation={countryConfirmation}
                setCourseConfirmation={setCourseConfirmation}
                setPlatformConfirmation={setPlatformConfirmation}
                setRuntimeConfirmation={setRuntimeConfirmation}
                isBusinessUser={userRole == userRoles.instructor ? true : false}
              />
              {userRole == userRoles.instructor && (
                <SetCourseDetails
                  step={"3"}
                  studentsCount={studentsCount}
                  startDate={startDate}
                  endDate={endDate}
                  setStudentsCount={setStudentsCount}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  selectedPackage={selectedPackage}
                  setSelectedPackage={setSelectedPackage}
                  platformConfirmation={platformConfirmation}
                  setCourseConfirmation={setCourseConfirmation}
                />
              )}
              <SelectDuration
                step={userRole == userRoles.instructor ? "4" : "3"}
                country={country ? country : ""}
                hours={hours}
                setHours={setHours}
                setAmount={setAmount}
                setRuntime={setRuntime}
                selectedPackage={selectedPackage}
                setSelectedPackage={setSelectedPackage}
                platform={platform}
                studentsCount={studentsCount}
                startDate={startDate}
                endDate={endDate}
                platformConfirmation={platformConfirmation}
                setRuntimeConfirmation={setRuntimeConfirmation}
                courseConfirmation={courseConfirmation}
                isBusinessUser={userRole == userRoles.instructor ? true : false}
              />
              <MakePayment
                step={userRole == userRoles.instructor ? "5" : "4"}
                country={country}
                makePayment={makePayment}
                amount={amount}
                runtimeConfirmation={runtimeConfirmation}
              />
            </div>

            <div className="accordion-wrap setup-accordion">
              <h2>FAQs</h2>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  What is a Cloud Machine?
                </span>
                <div className="accordion-content">
                  <p>
                    A Cloud machine is simply a computer system with a very
                    high-end hardware that you can access from anywhere in the
                    world using a laptop or PC.
                  </p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  How does it work?
                </span>
                <div className="accordion-content">
                  <p>
                    We install all the software (like Unreal engine, Epic games
                    store etc) you need in advance in the cloud machine. This
                    way all you need to do is simply hit the connect button and
                    you’re up and running in your browser.
                  </p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  What if I don’t use all the hours I have in a month?
                </span>
                <div className="accordion-content">
                  <p>
                    Since the machines are pre built and allocated to you for
                    the time period, if you don’t use your hours, they won’t
                    carry forward and hence will expire. Please choose your
                    hours plan carefully.
                  </p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  Can I add more hours later?
                </span>
                <div className="accordion-content">
                  <p>Yes, you can add more hours as and when you choose.</p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  Do my computer specs matter?
                </span>
                <div className="accordion-content">
                  <p>
                    Nope. All you need is a stable 2 mbps internet connection
                    and Chrome Browser.
                  </p>
                </div>
              </div>
            </div>
          </main>

          <SidebarSummary
            country={country}
            platformActive={platform}
            platformsList={platforms}
            amount={amount}
            runtime={runtime}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SetupMachine;

import moment from "moment";
import { Fragment } from "react/cjs/react.production.min";
import { IconUnity, IconUnreal } from "../Icons";
import Countdown from "../ui/countdown";
import UploadEmails from "../ui/UploadEmails";

const MachineInfo = ({
  timeLeft,
  totalHours,
  vms,
  instanceStatus,
  machineInstanceStatus,
  timer,
  duration,
  configPopup,
  platform,
  startDate,
  endDate,
  role,
  packId,
}) => {
  return (
    <div className="machine-info">
      <div className="machine-icon">
        {platform === "Unreal Engine" && <IconUnreal />}
        {platform === "Unity Engine" && <IconUnity />}
      </div>
      <div>
        <p className="machine-name">
          {platform} {vms && `for ${vms} VMs`} <span className="dot"></span>{" "}
          {timeLeft} <span>of {totalHours} hours left</span>
        </p>
        <p className="machine-status">
          <span className={instanceStatus.toLowerCase()}></span>
          <span className="status">{instanceStatus.toLowerCase()}</span>{" "}
          {instanceStatus.toLowerCase() == "processing" && " - "}
          {instanceStatus.toLowerCase() == machineInstanceStatus.processing && (
            <span>
              &nbsp;
              <Countdown maxValue={timer} duration={duration} />
              {" left"}
            </span>
          )}
          {startDate && (
            <Fragment>
              <span className="dot"></span>
              <span>
                {moment(startDate).format("MMM Do 'YY")} -{" "}
                {moment(endDate).format("MMM Do 'YY")}
              </span>
            </Fragment>
          )}
          <span className="dot"></span>
          {role !== "INSTRUCTOR" ? (
            <span className="config-link" onClick={() => configPopup(packId)}>
              Config Details
            </span>
          ) : (
            <UploadEmails packId={packId} />
          )}
        </p>
      </div>
    </div>
  );
};

export default MachineInfo;

import { useEffect, useRef, useState } from "react";
import { Bars } from "react-loader-spinner";
import Countdown from "../ui/countdown";

const ActionBtnStudent = ({
  timer,
  duration,
  instanceStatus,
  machineInstanceStatus,
  machineActionCompleted,
  startMachine,
  connectMachine,
  stopMachine,
  setTooltip,
  tooltip,
  packId,
}) => {
  const tooltipWrap = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipWrap.current && !tooltipWrap.current.contains(event.target)) {
        setTooltip(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipWrap, setTooltip]);

  return (
    <div className="machine-btn">
      <div className="tooltip-wrap btns-wrap" ref={tooltipWrap}>
        {tooltip && (
          <div className="tooltip wide">
            Please wait for your Instructor to start the machine
          </div>
        )}
        {instanceStatus.toLowerCase() == machineInstanceStatus.offline ||
        instanceStatus.toLowerCase() == machineInstanceStatus.processing ? (
          <button
            className="btn btn-light disabled"
            onClick={(e) => connectMachine(packId)}
          >
            Connect
          </button>
        ) : (
          <button
            className="btn btn-light"
            onClick={(e) => connectMachine(packId)}
          >
            Connect
          </button>
        )}
      </div>
    </div>
  );
};

export default ActionBtnStudent;

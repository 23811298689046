import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import heroVideo from "../hero-unit.mp4";
import hardwareImg from "../images/hardware.svg";
import epicGames from "../images/epic-games.png";
import QuixelBridge from "../images/quixel-bridge.png";
import training from "../images/training.svg";
import production from "../images/production.svg";
import practicing from "../images/practicing.svg";
import instances from "../images/instances.svg";

import Modal from "../components/Modal";
import FormSignup from "../components/popups/FormSignup";
import FormLogin from "../components/popups/FormLogin";
import FormContactUs from "../components/popups/FormContactUs";
import FormForgotPassword from "../components/popups/FormForgotPassword";
import { useUserAuth } from "../store/auth-context";

const Home = () => {
  document.body.classList.remove("grey");

  const { user } = useUserAuth();

  const [signUpPopup, setSignUpPopup] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [contactPopup, setContactPopup] = useState(false);
  const [forgotPopup, setForgotPopup] = useState(false);

  const showSignUp = () => {
    setSignUpPopup(true);
  };
  const hideSignUp = () => {
    setSignUpPopup(false);
  };
  const showLogin = () => {
    setLoginPopup(true);
  };
  const hideLogin = () => {
    setLoginPopup(false);
  };
  const showContact = () => {
    setContactPopup(true);
  };
  const hideContact = () => {
    setContactPopup(false);
  };
  const hideForgotPopup = () => {
    setForgotPopup(false);
  };
  const switchPopup = () => {
    setLoginPopup(false);
    setForgotPopup(true);
  };

  return (
    <Fragment>
      <div className="pre-header container">
        <div className="logo">MetaDesk</div>
        <nav>
          <ul>
	    <li>
              <span><a href="/SwiftShader">SwiftShader</a></span>
	    </li>
            <li>
              <span onClick={showContact}>Contact Us</span>
            </li>
            {!user && (
              <li>
                <span onClick={showLogin}>Login</span>
              </li>
            )}
            {!user && (
              <li>
                <button className="btn btn-dark btn-sm" onClick={showSignUp}>
                  Sign Up
                </button>
              </li>
            )}
            {user && (
              <li>
                <Link to={"/dashboard"} className="btn btn-dark btn-sm">
                  Dashboard
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <header className="container">
        <div className="intro">
          <h1>
            Run Unreal Engine <span>on any computer</span> with a single click
          </h1>
          <p>
            Just open your browser, access MetaDesk and boom, you're ready to
            go.
          </p>
          <div className="btns-wrap">
            {!user && (
              <button className="btn btn-light" onClick={showSignUp}>
                Sign Up
              </button>
            )}
            <button className="btn btn-dark border" onClick={showContact}>
              Contact Us
            </button>
          </div>
        </div>
        <div className="video">
          <video autoPlay loop muted playsInline disableRemotePlayback>
            <source src={heroVideo} type="video/mp4" />
            <span>Sorry, your browser doesn't support embedded videos.</span>
          </video>
        </div>
      </header>

      <section className="features container">
        <div className="comparison">
          <h2>Don't waste thousands of dollars on upgrading your computer</h2>
          <p>
            MetaDesk delivers the most powerful experience from the cloud at a
            fraction of the cost.
          </p>
          <div className="cost-lg">
            <span>Avg cost of upgrading your computer: $ 2,500</span>
            <progress value="100" max="100"></progress>
          </div>
          <div className="cost-sm">
            <span>MetaDesk cost</span>
            <progress value="4" max="100"></progress>
          </div>
        </div>
        <div
          className="instances"
          style={{ backgroundImage: `url(${instances})` }}
        >
          <h2>
            Run multiple <br />
            instances, easily
          </h2>
          <p>
            MetaDesk is great for instructors and students alike! Manage and
            schedule multiple sessions on VMs, anywhere in the world.
          </p>
        </div>
        <div className="hardware">
          <img src={hardwareImg} alt="" />
          <h2>Runs on the best hardware in the cloud</h2>
          <p>
            RTX capable GPUs support features including Real Time Ray Tracing,
            Accelerated Batch Rendering and more!
          </p>
        </div>
        <div className="tools">
          <h2>No messy installation setup required</h2>
          <p>
            Enhance your real time development experience with pre-installed
            tools like the Epic Games Store and Quixel Bridge.
          </p>
          <div className="tools-logos">
            <img src={epicGames} alt="Epic Games Store" />
            <img src={QuixelBridge} alt="Quixel Bridge" />
          </div>
        </div>
      </section>

      <section className="scenarios container">
        <h2>Use MetaDesk for</h2>
        <div className="scenarios__list">
          <div className="scenario">
            <img src={training} alt="Training" />
            <h3>Training</h3>
            <p>
              Train on the Unreal Engine unhindered while achieving your career
              goals! All this without burning a hole in your pocket!
            </p>
          </div>
          <div className="scenario">
            <img src={production} alt="Production" />
            <h3>Production</h3>
            <p>
              Supercharge your production workflow with our heavy duty machines.
              Scale while simultaneously reducing CapEx and Win!
            </p>
          </div>
          <div className="scenario">
            <img src={practicing} alt="Practicing" />
            <h3>Practicing</h3>
            <p>
              Keep your Unreal Engine skills fresh before an interview. Hit the
              ground running when you land that new gig!
            </p>
          </div>
        </div>
      </section>

      <section className="cta container">
        <h2>Get an unreal Unreal experience.</h2>
        <div className="btns-wrap">
          {!user && (
            <button className="btn btn-dark" onClick={showSignUp}>
              Sign Up
            </button>
          )}

          <button className="btn btn-light border" onClick={showContact}>
            Contact Us
          </button>
        </div>
      </section>

      <footer className="container">
        Copyright &copy; 2022 Vimobin Labs Private Limited. All rights reserved. &nbsp;
          <a target="_blank" href="https://www.metadesk.run/TAC.html">Terms</a> &nbsp; | &nbsp;
          <a target="_blank" href="https://www.metadesk.run/PrivacyPolicy.html">Privacy Policy</a> &nbsp; | &nbsp;
          <a target="_blank" href="https://www.metadesk.run/RefundPolicy.html">Refund Policy</a> &nbsp; | &nbsp;
          <a target="_blank" href="https://www.metadesk.run/AboutUs.html">About Us</a>	
      </footer>

      {signUpPopup && (
        <Modal hidePopup={hideSignUp}>
          <FormSignup />
        </Modal>
      )}
      {loginPopup && (
        <Modal hidePopup={hideLogin}>
          <FormLogin forgotPopup={switchPopup} />
        </Modal>
      )}
      {contactPopup && (
        <Modal hidePopup={hideContact}>
          <FormContactUs hidePopup={hideContact} />
        </Modal>
      )}
      {forgotPopup && (
        <Modal hidePopup={hideForgotPopup}>
          <FormForgotPassword hidePopup={hideForgotPopup} />
        </Modal>
      )}
    </Fragment>
  );
};

export default Home;

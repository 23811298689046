import { FieldPath } from "firebase/firestore";
import { useState } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { useUserAuth } from "../../store/auth-context";
import Modal from "../Modal";

const UploadEmails = ({ packId }) => {
	const { user } = useUserAuth();

	const [showPopup, setShowPopup] = useState();

	const handleFileChange = (e) => {
		sendEmail(e.target.files[0]);
	};

	const sendEmail = (file) => {
		let formData = new FormData();
		formData.append("attachment", file);
		formData.append("packId", packId);

		const url = `${process.env.REACT_APP_API_URL}/pack/emails`;

		fetch(`${url}`, {
			method: "POST",
			body: formData,
			headers: new Headers({
				idtoken: user.accessToken,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Fragment>
			<span className="upload-link">
				<span className="upload-file">Upload Emails</span>
				<input
					type="file"
					name="file"
					accept=".csv, .txt"
					onChange={handleFileChange}
				/>
			</span>

			{showPopup && (
				<Modal>
					<Fragment>
						<h2>File sent</h2>
						<div className="form-row">
							<button type="submit" className="btn btn-dark btn-close">
								Close
							</button>
						</div>
					</Fragment>
				</Modal>
			)}
		</Fragment>
	);
};

export default UploadEmails;

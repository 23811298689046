import { useRef, useState } from "react";
import { useUserAuth } from "../../store/auth-context";
import { useNavigate } from "react-router-dom";
import passwordImg from "../../images/icon-password.svg";
import { Bars } from "react-loader-spinner";

const FormLogin = (props) => {
  const userEmail = useRef();
  const userPass = useRef();

  const { logIn } = useUserAuth();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submissionInProgress, setSubmissionInProgress] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmissionInProgress(true);
    try {
      await logIn(userEmail.current.value, userPass.current.value);
      //props.hidePopup();
      localStorage.setItem("signup_username", "");
      setSubmissionInProgress(false);
      navigate("/dashboard");
    } catch (error) {
      setErrorMessage(
        "Unable to login. Please cross check your login details."
      );
      setSubmissionInProgress(false);
      console.log(error.message);
    }
  };

  return (
		<div>
			<h2>Login</h2>
			<form onSubmit={handleSubmit}>
				<div className="form-row">
					<input
						type="email"
						placeholder="Enter Your Email Address"
						name="Email"
						ref={userEmail}
						onChange={(e) => setErrorMessage("")}
					/>
				</div>
				<div className="form-row">
					<input
						type={showPass ? "text" : "password"}
						placeholder="Enter Your Password"
						ref={userPass}
						onChange={(e) => setErrorMessage("")}
					/>
					<img
						src={passwordImg}
						alt=""
						className="icon-password"
						onClick={() => setShowPass(!showPass)}
					/>
				</div>
				<div className="modal-link">
					<span onClick={props.forgotPopup}>Forgot Password?</span>
				</div>
				{errorMessage && <div className="error">{errorMessage}</div>}
				<div className="form-row">
					<button type="submit" className="btn btn-dark">
						{submissionInProgress ? (
							<Bars color="#C4F34E" height={20} width={49} />
						) : (
							"Login"
						)}
					</button>
				</div>
			</form>
		</div>
	);
};

export default FormLogin;

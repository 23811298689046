import { useEffect, useState } from "react";

export default function Countdown({ maxValue, duration }) {
  const [counter, setCounter] = useState(maxValue);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), duration * 1000);
  }, [counter]);

  return <span className="App">{counter} mins</span>;
}

import { useState, Fragment } from "react";

import downArrow from "../../images/down-arrow.svg";
import Dropdown from "../ui/Dropdown";

const SelectCountry = ({
  step,
  country,
  setCountry,
  setAmount,
  setCountryConfirmation,
  setPlatformConfirmation,
  setRuntimeConfirmation,
  setCourseConfirmation,
}) => {
  const [countryError, setCountryError] = useState("");
  const [componentClosed, setComponentClosed] = useState("");

  const handleSelectChange = (country) => {
    setCountry(country);
    setAmount("");
    setCountryConfirmation(false);
  };

  const handleConfirmation = () => {
    if (country) {
      setCountryConfirmation(true);
      setCountryError("");
      setComponentClosed(true);
    } else {
      setCountryError("This field is required");
    }
  };

  const handleChange = () => {
    setCountryError("");
    setComponentClosed(false);
    setCountryConfirmation(false);
    setPlatformConfirmation(false);
    setRuntimeConfirmation(false);
    setCourseConfirmation(false);
  };

  return (
    <div className="setup-step first-step">
      <span className="setup-num">{step}</span>
      <div className="setup-content">
        <h2>Choose Your Country</h2>

        {(!country || !componentClosed) && (
          <Fragment>
            <Dropdown
              selectedCountry={country}
              onSelectCountry={handleSelectChange}
            />

            {countryError ? <div className="error">{countryError}</div> : ""}
            <div className="instructions">
              <p>
                <strong>Why do we need this?</strong>
              </p>
              <p className="faded">
                This helps us know where we need to configure your Cloud Machine
                in terms of location. The closer it is to you, the faster it’ll
                be!
              </p>
            </div>
          </Fragment>
        )}

        {country && componentClosed && (
          <div>
            {country}{" "}
            <span onClick={handleChange} className="reopen">
              Change
            </span>
          </div>
        )}

        {!componentClosed && (
          <button
            className="btn btn-light"
            onClick={() => handleConfirmation()}
          >
            Confirm Country
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectCountry;

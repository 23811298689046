import stripeLogo from "../../images/StripeLogo.svg";
import CurrencyChar from "../ui/CurrencyChar";

const SidebarSummary = (props) => {

	return (
		<aside className="siderbar">
			<h3>Summary</h3>
			{!props.country && !props.platform && !props.hours && (
				<p>All your selections will be shown here</p>
			)}

			{props.country && (
				<ul className="selected-values first">
					<li>
						<span>Country</span>
						<span>{props.country}</span>
					</li>
				</ul>
			)}

			{props.platformActive &&
				props.platformsList.map((platform, i) => {
					if (platform.name === props.platformActive) {
						return (
							<ul className="selected-values" key={i}>
								<li>
									<span>Platform</span>
									<span>{platform.name}</span>
								</li>
								<li>
									<span>CPU</span>
									<span>{platform.cpu}</span>
								</li>
								<li>
									<span>CPU Cores</span>
									<span>{platform.cpu_cores}</span>
								</li>
								<li>
									<span>RAM</span>
									<span>{platform.ram}</span>
								</li>
								<li>
									<span>GPU</span>
									<span>{platform.gpu}</span>
								</li>
								<li>
									<span>GPU RAM</span>
									<span>{platform.gpu_ram}</span>
								</li>
							</ul>
						);
					}
				})}

			{props.runtime && (
				<ul className="selected-values">
					<li>
						<span>Runtime</span>
						<span>{props.runtime} Hours</span>
					</li>
				</ul>
			)}

			{props.amount && (
				<ul className="selected-values last">
					<li>
						<span>Total</span>
						<span className="large-txt">
							{CurrencyChar(props.country)}
							{props.amount}
						</span>
					</li>
				</ul>
			)}

			<div className="stripe_logo">
				<img src={stripeLogo} alt="Stripe Logo" />
			</div>
		</aside>
	);
};

export default SidebarSummary;

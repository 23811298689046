const FaqSidebar = () => {

	const QuestionClicked = (event) => {
		event.target.classList.toggle("open");
	};

	return (
		<aside className="siderbar">
			<h2>FAQs</h2>
			<div className="accordion-wrap">
				<div className="accordion">
					<span className="accordion-title" onClick={QuestionClicked}>
						What is a Cloud Machine?
					</span>
					<div className="accordion-content">
						<p>
							A Cloud machine is simply a computer system with a very high-end
							hardware that you can access from anywhere in the world using a
							laptop or PC.
						</p>
					</div>
				</div>
				<div className="accordion">
					<span className="accordion-title" onClick={QuestionClicked}>
						How does it work?
					</span>
					<div className="accordion-content">
						<p>
							We install all the software (like Unreal engine, Epic games store
							etc) you need in advance in the cloud machine. This way all you
							need to do is simply hit the connect button and you’re up and
							running in your browser.
						</p>
					</div>
				</div>
				<div className="accordion">
					<span className="accordion-title" onClick={QuestionClicked}>
						What if I don’t use all the hours I have in a month?
					</span>
					<div className="accordion-content">
						<p>
							Since the machines are pre built and allocated to you for the time
							period, if you don’t use your hours, they won’t carry forward and
							hence will expire. Please choose your hours plan carefully.
						</p>
					</div>
				</div>
				<div className="accordion">
					<span className="accordion-title" onClick={QuestionClicked}>
						Can I add more hours later?
					</span>
					<div className="accordion-content">
						<p>Yes, you can add more hours as and when you choose.</p>
					</div>
				</div>
				<div className="accordion">
					<span className="accordion-title" onClick={QuestionClicked}>
						Do my computer specs matter?
					</span>
					<div className="accordion-content">
						<p>
							Nope. All you need is a stable 2 mbps internet connection and Chrome Browser.
						</p>
					</div>
				</div>
			</div>

			<span>
				<a href="/faqs" className="more-link">
					See All FAQs
				</a>
			</span>
		</aside>
	);
}

export default FaqSidebar;

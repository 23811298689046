import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyCRrJfFtwqonT6bHtltXLloXGeYTN5nX08",
	authDomain: "metadesk-run.firebaseapp.com",
	projectId: "metadesk-run",
	storageBucket: "metadesk-run.appspot.com",
	messagingSenderId: "586522402326",
	appId: "1:586522402326:web:433862e5c9cac235135f37"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };

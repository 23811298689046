const Modal = (props) => {
	return (
		<div>
			<div className="backdrop" onClick={props.hidePopup}></div>
			<div className="modal">{props.children}</div>
		</div>
	);
}

export default Modal;

import { useEffect, useRef } from "react";
import { Bars } from "react-loader-spinner";
import { Fragment } from "react/cjs/react.production.min";
import Countdown from "../ui/countdown";

const ActionBtnInstructor = ({
	startAll,
	stopAll,
	timer,
	duration,
	instanceStatus,
	machineInstanceStatus,
	machineActionCompleted,
	setTooltip,
	tooltip,
	startDate,
}) => {
	const tooltipWrap = useRef();
	useEffect(() => {
		function handleClickOutside(event) {
			if (tooltipWrap.current && !tooltipWrap.current.contains(event.target)) {
				setTooltip(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [tooltipWrap, setTooltip]);

  const today = new Date();
	const start = new Date(startDate);

  // console.log(
	// 	instanceStatus.toLowerCase(), machineInstanceStatus.processing, tooltip
	// );

	return (
		<div className="machine-btn">
			{today < start ? (
				<div className="btn-wrap">
					<button className="btn btn-light" disabled>
						Start All
					</button>
				</div>
			) : (
				<Fragment>
					{instanceStatus.toLowerCase() == machineInstanceStatus.offline ? (
						<button className="btn btn-light" onClick={(e) => startAll()}>
							{machineActionCompleted ? (
								"Start All"
							) : (
								<Bars color="#000" height={20} width={52} />
							)}
						</button>
					) : (
						<div>
							<div className="btn-wrap">
								<div className="tooltip-wrap" ref={tooltipWrap}>
									{instanceStatus.toLowerCase() ==
										machineInstanceStatus.processing &&
										tooltip && (
											<div className="tooltip">
												Set-up in progress, we'll have it ready in{" "}
												<Countdown maxValue={timer} duration={duration} />!
											</div>
										)}
								</div>
								<button
									className="btn btn-light off border"
									onClick={(e) => stopAll()}
								>
									{machineActionCompleted ? (
										"Stop All"
									) : (
										<Bars color="#de2800" height={20} width={51} />
									)}
								</button>
							</div>
						</div>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default ActionBtnInstructor;

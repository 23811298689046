import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import { auth } from "../config";
import { userRoles } from "../constants/userRoles";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [machine, setMachine] = useState({});
  const [userRole, setUserRole] = useState("");

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }
  function addName(name) {
    updateProfile(auth.currentUser, {
      displayName: name,
    });
  }
  async function changePassword(user, oldPassword, newPassword) {
    let result = false;
    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential)
      .then(async () => {
        await updatePassword(user, newPassword)
          .then(() => {
            result = true;
          })
          .catch((error) => {
            result = false;
          });
      })
      .catch(() => {
        result = false;
      });
    return result;
  }

  async function getUserRole() {
    await auth.currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        if (idTokenResult.claims.instructor) {
          setUserRole(userRoles.instructor);
        } else {
          setUserRole(userRoles.single);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      //console.log("Auth", currentUser);
      setUser(currentUser);
      if (currentUser) getUserRole();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        resetPassword,
        changePassword,
        addName,
        machine,
        setMachine,
        userRole,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}

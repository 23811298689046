import { useEffect, useState, Fragment } from "react";
import { Bars } from "react-loader-spinner";
import { useUserAuth } from "../../store/auth-context";
import ReCAPTCHA from "react-google-recaptcha";

const FormContactUs = ({ page, hidePopup }) => {
  const { user } = useUserAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [formProcessing, setFormProcessing] = useState("");

  useEffect(() => {
    if (user && Object.keys(user).length) {
      setName(user.displayName);
      setEmail(user.email);
    }
  }, [user]);
  const submitForm = (e) => {
    e.preventDefault();
    !name ? setNameError("This field is required") : setNameError("");
    !email ? setEmailError("This field is required") : setEmailError("");
    !message ? setMessageError("This field is required") : setMessageError("");
    !captchaValue
      ? setCaptchaError("captcha verification is required")
      : setCaptchaError("");

    if (name && email && message && captchaValue) {
      setFormProcessing(true);
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("Query", message);
      const url =
        page == "faq"
          ? process.env.REACT_APP_FAQ_CONTACT_FORM_URL
          : process.env.REACT_APP_CONTACT_FORM_URL;

      fetch(`${url}`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success == "false") {
            setErrorMessage(data.message);
          } else {
            setSuccessMessage(
              `Thanks! We’ll get in touch with you on ${email} soon.`
            );
            if (!user) {
              setName("");
              setEmail("");
            }
            setMessage("");
          }
          setFormProcessing(false);
        })
        .catch(() => {
          setErrorMessage("There was an error while submitting your form");
          setFormProcessing(false);
        });
    }
  };

  const handleCaptcha = (value) => {
    setCaptchaValue(value);
    value
      ? setCaptchaError("")
      : setCaptchaError("captcha verification is required");
  };

  return (
    <div>
      <h2>Contact Us</h2>
      {successMessage ? (
        <Fragment>
          <div className="form-row success">{successMessage}</div>
          <div className="form-row">
            <button
              type="submit"
              className="btn btn-dark btn-close"
              onClick={hidePopup}
            >
              Got It
            </button>
          </div>
        </Fragment>
      ) : (
        <form>
          {!user && (
            <Fragment>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="Enter Your Name"
                  name="Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError("");
                  }}
                />
                {nameError && <span className="error">{nameError}</span>}
              </div>
              <div className="form-row">
                <input
                  type="email"
                  placeholder="Enter Your Email Address"
                  name="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && <span className="error">{emailError}</span>}
              </div>
            </Fragment>
          )}
          <div className="form-row">
            <textarea
              placeholder="What's your query?"
              onChange={(e) => {
                setMessage(e.target.value);
                setMessageError("");
              }}
              value={message}
            ></textarea>
            {messageError && <span className="error">{messageError}</span>}
          </div>
          <div className="form-row row-captcha">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={handleCaptcha}
            />
            {captchaError && <span className="error">{captchaError}</span>}
          </div>
          <div className="form-row">
            {formProcessing ? (
              <button type="submit" className="btn btn-dark">
                <Bars color="#C4F34E" height={20} width={107} />
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-dark"
                onClick={(e) => submitForm(e)}
              >
                Get in Touch
              </button>
            )}

            {errorMessage && (
              <div className="form-row error">{errorMessage}</div>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default FormContactUs;

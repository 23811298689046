import { Fragment, useState } from "react";
import { Bars } from "react-loader-spinner";
import paymentIcons from "../../images/payment-icons.png";
import emojiFlag from "../../images/emoji-chequered-flag-apple.png";
import CurrencyChar from "../ui/CurrencyChar";

const MakePayment = ({ step, country, amount, makePayment, runtimeConfirmation }) => {

	const [paymentBtn, setPaymentBtn] = useState(true);

	const handlePaymentBtn = () => {
		setPaymentBtn(false);
		makePayment();
	};

  return (
		<div className="setup-step last-step">
			<span className="setup-num">{step}</span>
			<div className="setup-content">
				<h2 className="emoji-flag">
					Pay and initiate setup <img src={emojiFlag} alt="" />
				</h2>
				{runtimeConfirmation ? (
					<Fragment>
						<div className="instructions">
							<p className="faded">
								Your config looks great! Complete your payment and we’ll have it
								up and running for you within 5 mins.
							</p>
						</div>
						<button className="btn btn-light" onClick={handlePaymentBtn}>
							{paymentBtn ? (
								`Pay ${CurrencyChar(country)} ${amount} and get started`
							) : (
								<Bars color="#000" height={20} width={80} />
							)}
						</button>

						<div className="payment-img">
							<img src={paymentIcons} alt="" />
						</div>
					</Fragment>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default MakePayment;

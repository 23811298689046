import { Fragment } from "react";
import FaqSidebar from "../components/FaqSidebar";
import Header from "../components/Header";
import { useUserAuth } from "../store/auth-context";

//import UploadStudentsInfo from "../components/dashboard/UploadStudentsInfo";
import Machines from "../components/dashboard/Machines";
import { userRoles } from "../constants/userRoles";

const Dashboard = () => {
  document.body.classList.add("grey");
  const { userRole } = useUserAuth();

  return (
    <Fragment>
      <Header />
      <div className="container page-wrap">
        <main>
          {/* {userRole === userRoles.instructor && <UploadStudentsInfo />} */}

          <div className="content-wrap">
            <h2>Your Cloud Machines</h2>
            <Machines />
          </div>
        </main>
        <FaqSidebar />
      </div>
    </Fragment>
  );
};

export default Dashboard;

import { Fragment, useEffect, useRef, useState } from "react";

import flagUK from "../../images/flag-uk.svg";
import flagIN from "../../images/flag-in.svg";
import flagDE from "../../images/flag-de.svg";
import flagES from "../../images/flag-es.svg";

import downArrow from "../../images/down-arrow.svg";

const data = [
	{ id: "DE", label: "Germany", flag: flagDE },
	{ id: "IN", label: "India", flag: flagIN },
	{ id: "ES", label: "Spain", flag: flagES },
	{ id: "UK", label: "United Kingdom", flag: flagUK },
];

const SelectedItem = ({id}) => {
	return (
		data.map((item) => {
			if(item.id == id) {
				return (
					<Fragment key={item.id}>
						<img src={item.flag} alt="" /> {item.label}
					</Fragment>
				);
			}
		})
	)
}

const Dropdown = (props) => {
	const [isOpen, setOpen] = useState(false);
	const [items, setItem] = useState(data);
	const [selectedItem, setSelectedItem] = useState(props.selectedCountry);

	const dropdown = useRef(null);

	const toggleDropdown = () => setOpen(!isOpen);

	const handleItemClick = (id) => {
		selectedItem === id ? setSelectedItem(null) : setSelectedItem(id);
		setOpen(false);
		props.onSelectCountry(id);
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdown.current && !dropdown.current.contains(event.target)) {
				setOpen(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdown]);

	return (
		<div className="dropdown" ref={dropdown}>
			<div
				className="dropdown-header"
				onClick={toggleDropdown}
				style={{ backgroundImage: `url(${downArrow})` }}
			>
				{selectedItem
					? <SelectedItem id={items.find((item) => item.id == selectedItem).id} />
					: "Select a Country"}
				<i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
			</div>
			<div className={`dropdown-body ${isOpen && "open"}`}>
				{items.map((item) => (
					<div
						className="dropdown-item"
						onClick={(e) => handleItemClick(e.target.id)}
						id={item.id}
						key={item.id}
					>
							<img src={item.flag} alt="" /> {item.label}
					</div>
				))}
			</div>
		</div>
	);
};

export default Dropdown;

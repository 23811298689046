import { useState, useRef, Fragment } from "react";
import { Bars } from "react-loader-spinner";
import passwordImg from "../../images/icon-password.svg";
import { useUserAuth } from "../../store/auth-context";

const FormChangePassword = (props) => {
  const { user, changePassword } = useUserAuth();
  const oldPassword = useRef();
  const newPassword = useRef();
  const confirmNewPassword = useRef();

  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [submissionInProgress, setSubmissionInProgress] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = true;
    if (
      !oldPassword.current.value ||
      !newPassword.current.value ||
      !confirmNewPassword.current.value
    ) {
      setSuccessMessage("");
      setErrorMessage("All fields are required.");
      error = true;
    } else if (newPassword.current.value != confirmNewPassword.current.value) {
      setSuccessMessage("");
      setErrorMessage("New password and confirm password should be same.");
      error = true;
    } else if (
      newPassword.current.value.trim() == oldPassword.current.value.trim()
    ) {
      setSuccessMessage("");
      setErrorMessage("New password should not be same.");
      error = true;
    } else {
      setSuccessMessage("");
      setErrorMessage("");
      error = false;
    }

    if (!error) {
      setSuccessMessage("");
      setErrorMessage("");
      setSubmissionInProgress(true);
      try {
        const result = await changePassword(
          user,
          oldPassword.current.value,
          newPassword.current.value
        );
        setSubmissionInProgress(false);
        if (result) {
          setSuccessMessage("Your password has been updated successfully.");
          setErrorMessage("");
        } else {
          setSuccessMessage("");
          setErrorMessage("There was an error in updating your password.");
        }
      } catch (error) {
        setSuccessMessage("");
        setErrorMessage("There was an error in updating your password.");
        setSubmissionInProgress(false);
      }
    }
  };

  return (
    <div>
      <h2>Change Password</h2>
      {successMessage ? (
        <Fragment>
          <div className="success">{successMessage}</div>
          <div className="form-row">
            <button
              type="submit"
              className="btn btn-dark btn-close"
              onClick={props.hidePopup}
            >
              Got It
            </button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type={showPass ? "text" : "password"}
                placeholder="Enter Current Password"
                ref={oldPassword}
                onChange={() => setErrorMessage("")}
              />
              <img
                src={passwordImg}
                alt=""
                className="icon-password"
                onClick={() => setShowPass(!showPass)}
              />
            </div>
            <div className="modal-link">
              <span onClick={props.handleForgotPass}>Forgot Password?</span>
            </div>
            <div className="form-row">
              <input
                type={showNewPass ? "text" : "password"}
                placeholder="Enter Your New Password"
                ref={newPassword}
                onChange={() => setErrorMessage("")}
              />
              <img
                src={passwordImg}
                alt=""
                className="icon-password"
                onClick={() => setShowNewPass(!showNewPass)}
                onChange={() => setErrorMessage("")}
              />
            </div>
            <div className="form-row">
              <input
                type={showRePass ? "text" : "password"}
                placeholder="Re-enter New Password"
                ref={confirmNewPassword}
              />
              <img
                src={passwordImg}
                alt=""
                className="icon-password"
                onClick={() => setShowRePass(!showRePass)}
              />
            </div>
            {errorMessage && <div className="error">{errorMessage}</div>}
            <div className="form-row">
              <button type="submit" className="btn btn-dark">
                {submissionInProgress ? (
                  <Bars color="#C4F34E" height={20} width={150} />
                ) : (
                  "Change Password"
                )}
              </button>
            </div>
          </form>
        </Fragment>
      )}
    </div>
  );
};

export default FormChangePassword;

import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Modal from "../components/Modal";
import FormContactUs from "../components/popups/FormContactUs";

import imgStart from "../images/img-startmachine.png";
import imgConnect from "../images/img-connect.png";

const Faqs = () => {
  document.body.classList.add("grey");
  const [contactPopup, setContactPopup] = useState(false);

  const showContact = () => {
    setContactPopup(true);
  };
  const hideContact = () => {
    setContactPopup(false);
  };

  const QuestionClicked = (event) => {
    event.target.classList.toggle("open");
  };

  return (
    <Fragment>
      <Header />

      <div className="container">
        <h1 className="page-title">FAQs &amp; Help</h1>
        <div className="page-wrap">
          <aside className="siderbar">
            <h2>Categories</h2>
            <ul>
              <li>
                <span className="link">General</span>
              </li>
            </ul>
            <Link to="#" onClick={showContact} className="btn btn-dark">
              Contact Support
            </Link>
          </aside>
          <main className="faqs-wrap">
            <div className="accordion-wrap">
              <h2>General</h2>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  What is a Cloud Machine?
                </span>
                <div className="accordion-content">
                  <p>
                    A Cloud machine is simply a computer system with a very
                    high-end hardware that you can access from anywhere in the
                    world using a laptop or PC.
                  </p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  How does it work?
                </span>
                <div className="accordion-content">
                  <p>
                    We install all the software (like Unreal engine, Epic games
                    store etc) you need in advance in the cloud machine. This
                    way all you need to do is simply hit the connect button and
                    you’re up and running in your browser.
                  </p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  What if I don’t use all the hours I have in a month?
                </span>
                <div className="accordion-content">
                  <p>
                    Since the machines are pre built and allocated to you for
                    the time period, if you don’t use your hours, they won’t
                    carry forward and hence will expire. Please choose your
                    hours plan carefully.
                  </p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  Can I add more hours later?
                </span>
                <div className="accordion-content">
                  <p>Yes, you can add more hours as and when you choose.</p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  Do my computer specs matter?
                </span>
                <div className="accordion-content">
                  <p>
                    Nope. All you need is a stable 2 mbps internet connection
                    and Chrome Browser.
                  </p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  How do I access my Cloud Machine?
                </span>
                <div className="accordion-content">
                  <p>
                    Go To main dashboard where it says “Your Cloud Machines”.
                    Accessing the machine is a 3 step process.
                  </p>
                  <ol>
                    <li>
                      First you need to hit the “Start Machine” button.{" "}
                      <img src={imgStart} alt="" />
                    </li>
                    <li>
                      The Machine can take around 3-5 mins to start-up. Once the
                      machine is ready, you will see a Connect button.{" "}
                      <img src={imgConnect} alt="" />
                    </li>
                    <li>
                      After clicking the Connect button, your Chrome browser
                      will open up a new tab with a custom URL which will be
                      where you can start using your cloud machine.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  Why is my cloud machine screen moving slowly?
                </span>
                <div className="accordion-content">
                  <p>
                    This can happen if the quality of your internet connection
                    is not good. One way to rectify this is to reset your modem
                    or move closer to your modem for best experience.
                  </p>
                </div>
              </div>
              <div className="accordion">
                <span className="accordion-title" onClick={QuestionClicked}>
                  Do you support all browsers?
                </span>
                <div className="accordion-content">
                  <p>Currently, our service is only supported on Chrome.</p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {contactPopup && (
        <Modal hidePopup={hideContact}>
          <FormContactUs page="faq" hidePopup={hideContact} />
        </Modal>
      )}
    </Fragment>
  );
};

export default Faqs;

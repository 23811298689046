import { Fragment, useEffect, useRef, useState } from "react";
import { useUserAuth } from "../../store/auth-context";
import ButtonMailto from "../ui/ButtonMailTo";
import {
  machineInstanceAPIResponse,
  machineInstanceStatus,
  singleUserMachineConfig,
} from "../../constants/machines";
import Countdown from "../ui/countdown";
import { Bars } from "react-loader-spinner";
import Modal from "../Modal";
import ConfigDetails from "../popups/configDetails";
import EmptyDashboard from "./EmptyDashboard";
import MachineInfo from "./MachineInfo";
import ActionBtnSingle from "./ActionBtnSingle";
import ActionBtnInstructor from "./ActionBtnInstructor";
import { userRoles } from "../../constants/userRoles";
import ActionBtnStudent from "./ActionBtnStudent";

let focusEventListenerAdded = false;

const Machines = () => {
  const { user, userRole } = useUserAuth();
  const timer = 5;
  const duration = 60;

  const [haveMachines, setHaveMachines] = useState("");
  const [balance, setBalance] = useState("");
  const [pageLoaded, setPageLoaded] = useState("");
  const [machineStatus, setMachineStatus] = useState("");
  const [machineUrl, setMachineUrl] = useState("");
  const [configPopupShown, setConfigPopupShown] = useState("");
  const [machineActionCompleted, setMachineActionCompleted] = useState(true);
  const [tooltip, setTooltip] = useState(false);
  const [platform, setPlatform] = useState("");
  const [data, setData] = useState();
  const [machinesConfig, setMachinesConfig] = useState();
  const [selectedMachinePackId, setSelectedMachinePackId] = useState(0);
  const [isProcessing, setIsProcessing] = useState(0);

  useEffect(() => {
    if (Object.keys(user).length && !balance) {
      getBalance();
    }
    checkMachineStatus();
  }, [user, machineStatus, isProcessing]);

  const focusWindow = () => {
    getBalance();
  };

  if (user && user.uid && user.uid.length > 20 && !focusEventListenerAdded) {
    focusEventListenerAdded = true;
    window.addEventListener("focus", focusWindow);
  }

  const getBalance = () => {
    setMachineStatus("");
    fetch(`${process.env.REACT_APP_API_URL}/user/wallet/balance`, {
      method: "GET",
      headers: new Headers({
        idtoken: user.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.balance.length) {
          const result = [];
          const configs = [];
          let processing = 0;
          // let status = machineInstanceAPIResponse.running;
          data.balance.map((row, index) => {
            if (row.role != userRoles.instructor || !index) {
              if (
                data.balance[0].instanceStatus.toLowerCase() ==
                machineInstanceStatus.processing
              ) {
                processing++;
              }

              result.push({
                ...row,
              });
              configs.push({
                ...row.machineConfig,
                platform: row.platform,
                packId: row.packId,
              });
            }
          });
          setMachinesConfig(configs);
          setData(result);
          setBalance(data.balance[0]);
          setHaveMachines(true);
          setPlatform(data.balance[0].platform);
          if (
            data.balance[0].instanceStatus.toLowerCase() ==
            machineInstanceStatus.processing
          ) {
            setMachineStatus(machineInstanceAPIResponse.wait);
          } else if (
            data.balance[0].instanceStatus.toLowerCase() ==
            machineInstanceStatus.online
          ) {
            setMachineUrl(data.balance[0].url);
          }
          if (processing) {
            window.setTimeout(getBalance, 3 * 60 * 1000);
          }
          setIsProcessing(processing);
        }
        setPageLoaded(true);
      })
      .catch(() => {
        setPageLoaded(true);
      });
  };
  const checkMachineStatus = () => {
    console.log("processing", isProcessing);
    if (isProcessing) {
      let timeout = setTimeout(() => getBalance(), 30000);
    }
  };

  const startMachine = (packId) => {
    if ((balance.instanceStatus = machineInstanceStatus.offline)) {
      setMachineActionCompleted(false);
      setMachineStatus("");

      fetch(`${process.env.REACT_APP_API_URL}/user/slot`, {
        method: "POST",
        body: JSON.stringify({
          packId: packId,
        }),
        headers: new Headers({
          idtoken: user.accessToken,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          getBalance();
          setMachineStatus(data.status);
          setMachineUrl(data.url);
          if (data.status == machineInstanceAPIResponse.running) {
            setBalance({
              ...balance,
              instanceStatus: machineInstanceStatus.online,
            });
          } else if (data.status != machineInstanceAPIResponse.failed) {
            setBalance({
              ...balance,
              instanceStatus: machineInstanceStatus.processing,
            });
          }
          setMachineActionCompleted(true);
        })
        .catch(() => {
          setMachineActionCompleted(true);
        });
    }
  };

  const stopMachine = (packId) => {
    setMachineActionCompleted(false);
    fetch(`${process.env.REACT_APP_API_URL}/user/slot`, {
      method: "POST",
      body: JSON.stringify({
        packId: packId,
        terminate: true,
      }),
      headers: new Headers({
        idtoken: user.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setMachineStatus(machineInstanceAPIResponse.stopped);
        setBalance({
          ...balance,
          instanceStatus: machineInstanceStatus.offline,
        });
        setMachineActionCompleted(true);
        getBalance();
      })
      .catch(() => {
        setMachineActionCompleted(true);
      });
  };

  const startAll = () => {
    if ((balance.instanceStatus = machineInstanceStatus.offline)) {
      setMachineActionCompleted(false);
      setMachineStatus("");

      fetch(`${process.env.REACT_APP_API_URL}/pack/start`, {
        method: "POST",
        body: JSON.stringify({
          packId: balance.packId,
        }),
        headers: new Headers({
          idtoken: user.accessToken,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setMachineStatus(data.status);
          if (data.status != machineInstanceAPIResponse.failed) {
            setBalance({
              ...balance,
              instanceStatus: machineInstanceStatus.processing,
            });
            setMachineStatus(machineInstanceAPIResponse.wait);
          } else {
            setBalance({
              ...balance,
              instanceStatus: machineInstanceStatus.offline,
            });
          }
          setMachineActionCompleted(true);
          getBalance();
        })
        .catch(() => {
          setMachineActionCompleted(true);
        });
    }
  };

  const stopAll = () => {
    setMachineActionCompleted(false);
    fetch(`${process.env.REACT_APP_API_URL}/pack/stop`, {
      method: "POST",
      body: JSON.stringify({
        packId: balance.packId,
        terminate: true,
      }),
      headers: new Headers({
        idtoken: user.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setMachineStatus(machineInstanceAPIResponse.stopped);
        setBalance({
          ...balance,
          instanceStatus: machineInstanceStatus.offline,
        });
        setMachineActionCompleted(true);
        getBalance();
      })
      .catch(() => {
        setMachineActionCompleted(true);
      });
  };

  const connectMachine = (packId) => {
    if (packId) {
      let role = "";
      let machineName = "";
      data.map((machine) => {
        if (machine.packId == packId) {
          role = machine.role;
          machineName = machine.machineName;
        }
      });

      const url = `${process.env.REACT_APP_API_URL}/slot/connect`;
      const obj = { url, role, packId, machineName };
      const hash = btoa(JSON.stringify(obj));
      const win = window.open("connect#" + hash, "_blank");
    } else {
      setTooltip(true);
    }
  };

  const showConfigDetailsPopup = (packId) => {
    setSelectedMachinePackId(packId);
    setConfigPopupShown(true);
  };

  return (
    <Fragment>
      {pageLoaded ? (
        <Fragment>
          {haveMachines && (
            <div className="machines">
              {data.map((machine, index) => (
                <div className="machine" key={index}>
                  <MachineInfo
                    platform={machine.machineName}
                    timeLeft={machine.timeLeft}
                    totalHours={machine.totalHours}
                    vms={machine.vms}
                    instanceStatus={machine.instanceStatus}
                    timer={timer}
                    duration={duration}
                    machineInstanceStatus={machineInstanceStatus}
                    configPopup={showConfigDetailsPopup}
                    startDate={machine.startDate}
                    endDate={machine.endDate}
                    role={machine.role}
                    packId={machine.packId}
                  />
                  {machine.role == userRoles.instructor ? (
                    <ActionBtnInstructor
                      instanceStatus={machine.instanceStatus}
                      timer={timer}
                      duration={duration}
                      machineInstanceStatus={machineInstanceStatus}
                      machineActionCompleted={machineActionCompleted}
                      startAll={startAll}
                      stopAll={stopAll}
                      setTooltip={setTooltip}
                      tooltip={tooltip}
                      startDate={machine.startDate}
                      endDate={machine.endDate}
                      packId={machine.packId}
                    />
                  ) : (
                    <Fragment>
                      {machine.role === "STUDENT" ? (
                        <ActionBtnStudent
                          instanceStatus={machine.instanceStatus}
                          timer={timer}
                          duration={duration}
                          machineInstanceStatus={machineInstanceStatus}
                          machineActionCompleted={machineActionCompleted}
                          startMachine={startMachine}
                          connectMachine={connectMachine}
                          stopMachine={stopMachine}
                          setTooltip={setTooltip}
                          tooltip={tooltip}
                          role={machine.role}
                          packId={machine.packId}
                        />
                      ) : (
                        <ActionBtnSingle
                          instanceStatus={machine.instanceStatus}
                          timer={timer}
                          duration={duration}
                          machineInstanceStatus={machineInstanceStatus}
                          machineActionCompleted={machineActionCompleted}
                          startMachine={startMachine}
                          connectMachine={connectMachine}
                          stopMachine={stopMachine}
                          setTooltip={setTooltip}
                          tooltip={tooltip}
                          role={machine.role}
                          packId={machine.packId}
                        />
                      )}
                    </Fragment>
                  )}
                </div>
              ))}

              <p>
                Need help? Email us anytime on{" "}
                <ButtonMailto
                  label="help@metadesk.run"
                  mailto="mailto:help@metadesk.run"
                />
              </p>
            </div>
          )}
          {!haveMachines && <EmptyDashboard />}
        </Fragment>
      ) : (
        <div className="empty-dashboard">
          <Bars color="#C4F34E" height={80} width={80} />
        </div>
      )}

      {configPopupShown && (
        <Modal hidePopup={() => setConfigPopupShown(false)}>
          <ConfigDetails
            platform={platform}
            data={
              userRole == userRoles.instructor
                ? singleUserMachineConfig
                : machinesConfig
            }
            isStatic={userRole == userRoles.instructor ? 1 : 0}
            selectedMachinePackId={selectedMachinePackId}
            setConfigPopupShown={setConfigPopupShown}
          />
        </Modal>
      )}
    </Fragment>
  );
};

export default Machines;

import { useEffect, useRef, useState } from "react";
import { Bars } from "react-loader-spinner";
import Countdown from "../ui/countdown";

const ActionBtnSingle = ({
  timer,
  duration,
  instanceStatus,
  machineInstanceStatus,
  machineActionCompleted,
  startMachine,
  connectMachine,
  stopMachine,
  setTooltip,
  tooltip,
  packId,
}) => {
  const tooltipWrap = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipWrap.current && !tooltipWrap.current.contains(event.target)) {
        setTooltip(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipWrap, setTooltip]);

  useEffect(() => {
    if (machineActionCompleted) {
      setLoading(false);
    }
  }, [machineActionCompleted]);

  return (
    <div className="machine-btn">
      {instanceStatus.toLowerCase() == machineInstanceStatus.offline ? (
        <button
          className="btn btn-light"
          onClick={(e) => {
            startMachine(packId);
            setLoading(true);
          }}
        >
          {machineActionCompleted || !loading ? (
            "Start Machine"
          ) : (
            <Bars color="#000" height={20} width={80} />
          )}
        </button>
      ) : (
        <div>
          <div className="btn-wrap">
            <div className="tooltip-wrap" ref={tooltipWrap}>
              {instanceStatus.toLowerCase() ==
                machineInstanceStatus.processing &&
                tooltip && (
                  <div className="tooltip">
                    Set-up in progress, we'll have it ready in{" "}
                    <Countdown maxValue={timer} duration={duration} />!
                  </div>
                )}
              <button
                className={
                  instanceStatus.toLowerCase() ==
                  machineInstanceStatus.processing
                    ? "btn btn-light disabled"
                    : "btn btn-light"
                }
                onClick={(e) => connectMachine(packId)}
              >
                Connect
              </button>
            </div>
            <button
              className="btn btn-light off border"
              onClick={(e) => {
                stopMachine(packId);
                setLoading(true);
              }}
            >
              {machineActionCompleted || !loading ? (
                "Stop"
              ) : (
                <Bars color="#de2800" height={20} width={80} />
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionBtnSingle;

import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from './pages/Home';
import Dashboard from './pages/Dashboard';
import Faqs from './pages/Faqs';
import SetupMachine from './pages/SetupMachine';
import Welcome from "./pages/Welcome";

import './scss/style.scss';
import { UserAuthContextProvider } from "./store/auth-context";
import Profile from "./pages/Profile";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailed from "./pages/PaymentFailed";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
	return (
		<BrowserRouter>
			<UserAuthContextProvider>
				<Routes>
					<Route path="/" exact element={<HomePage />} />
					<Route
						path="/dashboard"
						element={
							<ProtectedRoute>
								<Dashboard />
							</ProtectedRoute>
						}
					/>
					<Route path="/faqs" element={<Faqs />} />
					<Route
						path="/welcome"
						element={
							<ProtectedRoute>
								<Welcome />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/setup"
						element={
							<ProtectedRoute>
								<SetupMachine />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/profile"
						element={
							<ProtectedRoute>
								<Profile />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/success"
						element={
							<ProtectedRoute>
								<PaymentSuccess />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/failed"
						element={
							<ProtectedRoute>
								<PaymentFailed />
							</ProtectedRoute>
						}
					/>
					{/* <Route path="*" element={<NotFound />} /> */}
				</Routes>
			</UserAuthContextProvider>
		</BrowserRouter>
	);
}

export default App;

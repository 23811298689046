import { Fragment, useEffect } from "react";
import Header from "../components/Header";
import { singleUserMachineConfig } from "../constants/machines";
import ButtonMailto from "../components/ui/ButtonMailTo";
import { countryCurrencySymbol } from "../constants/currency";
import { useUserAuth } from "../store/auth-context";
import { userRoles } from "../constants/userRoles";
import { formatDate } from "../helper";

const PaymentFailed = () => {
  document.body.classList.add("grey");
  const orderData = JSON.parse(localStorage.getItem("order_data"));
  const { userRole } = useUserAuth();

  const retryPayment = () => {
    window.location.href = orderData.payment_url;
  };
  return (
    <Fragment>
      <Header />

      <div className="container">
        <div className="page-wrap">
          <main>
            <div className="content-wrap">
              <div className="payment-status payment-failed">
                <h1>Payment Unsuccessful</h1>
                <p>
                  Oops, something went wrong and your payment didn’t go through.
                  Please try again.
                </p>
                <p>
                  If you have any questions, email us anytime on{" "}
                  <ButtonMailto
                    label="help@metadesk.run"
                    mailto="mailto:help@metadesk.run"
                  />
                </p>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => retryPayment()}
                >
                  Retry Payment
                </button>
              </div>
            </div>
          </main>

          {orderData && (
            <aside className="siderbar">
              <h3>Summary</h3>
              <ul className="selected-values first">
                <li>
                  <span className="col">Country</span>
                  <span className="col">{orderData.country}</span>
                </li>
              </ul>
              <ul className="selected-values">
                {singleUserMachineConfig.map((row, i) => (
                  <li className="row" key={i}>
                    <span className="col">{row.label}</span>
                    <span className="col">{row.value}</span>
                  </li>
                ))}
              </ul>
              {userRole == userRoles.instructor && (
                <ul className="selected-values">
                  <li>
                    <span className="col">Concurrent Students</span>
                    <span className="col">{orderData.studentsCount}</span>
                  </li>
                  <li>
                    <span className="col">Course Dates</span>
                    <span className="col">
                      {formatDate(orderData.startDate)} -{" "}
                      {formatDate(orderData.endDate)}{" "}
                    </span>
                  </li>
                </ul>
              )}
              <ul className="selected-values">
                <li>
                  <span className="col">Runtime</span>
                  <span className="col">{orderData.hours} hours</span>
                </li>
              </ul>
              <ul className="selected-values">
                <li>
                  <span className="col">Total</span>
                  <span className="col large-txt">
                    {countryCurrencySymbol[orderData.country]}{" "}
                    {orderData.amount}
                  </span>
                </li>
              </ul>
            </aside>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentFailed;

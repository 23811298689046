import { useState, useEffect, Fragment } from "react";
import { useUserAuth } from "../../store/auth-context";
import CurrencyChar from "../ui/CurrencyChar";

const SelectDuration = ({
  step,
  country,
  hours,
  setHours,
  setAmount,
  setRuntime,
  platform,
  selectedPackage,
  setSelectedPackage,
  platformConfirmation,
  setRuntimeConfirmation,
  courseConfirmation,
  isBusinessUser,
  studentsCount,
  startDate,
  endDate,
}) => {
  const { user } = useUserAuth();
  const [packages, setPackages] = useState("");
  const [packageError, setPackageError] = useState("");
  const [componentClosed, setComponentClosed] = useState("");

  const previousStepConfirmation = isBusinessUser
    ? courseConfirmation
    : platformConfirmation;

  const handleConfirmation = () => {
    if (!packages) {
      setPackageError("Packages are not available for the selected location.");
    } else if (selectedPackage) {
      setRuntimeConfirmation(true);
      setPackageError("");
      setComponentClosed(true);
    } else {
      setPackageError("Please select the package");
    }
  };

  const handleChange = () => {
    setPackageError("");
    setRuntimeConfirmation(false);
    setComponentClosed(false);
  };

  useEffect(() => {
    if (!previousStepConfirmation) {
      setComponentClosed(false);
    }

    if (isBusinessUser && startDate && endDate && platform) {
      getBusinessUserPackages();
    } else if (country && !packages) {
      getSingleUserPackages();
    }
  }, [platform, previousStepConfirmation]);

  const getBusinessUserPackages = () => {
    setHours("");
    setAmount("");
    setRuntime("");
    setSelectedPackage("");
    setPackages("");

    fetch(`${process.env.REACT_APP_API_URL}/pack/cost`, {
      method: "POST",
      body: JSON.stringify({
        Platform: platform,
        VMs: studentsCount,
        StartAt: startDate.getTime(),
        ExpiresAt: endDate.getTime(),
        Region: country,
      }),
      headers: new Headers({
        idtoken: user.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (country) {
          const result = {};
          let count = 0;
          for (const [key, value] of Object.entries(data.prices)) {
            count++;
            if (result.hasOwnProperty(country)) {
              result[country].push({
                hours: key,
                price: value,
                id: count,
                platform: platform
              });
            } else {
              result[country] = [
								{
									hours: key,
									price: value,
									id: count,
									platform: platform,
								},
							];
            }
          }

          setPackages(result);
          const elements = document.getElementsByClassName('price');
          for (const element of elements) {
            element.innerHTML = "";
          }
          setPackageError("");
        }
      })
      .catch((e) => {
        setPackageError(
          "Packages are not available for the selected location."
        );
      });
  };

  const getSingleUserPackages = () => {
    fetch(`${process.env.REACT_APP_API_URL}/packs`, {
      method: "POST",
      body: JSON.stringify({
        idtoken: user.accessToken,
      }),
      headers: new Headers({
        idtoken: user.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (country) {
          const result = {};
          data.packs.map((row) => {
            if (result.hasOwnProperty(row.region)) {
							result[row.region].push({
								hours: row.hours,
								price: row.price,
								id: row.packId,
                platform: row.platform,
							});
						} else {
							result[row.region] = [
								{
									hours: row.hours,
									price: row.price,
									id: row.packId,
									platform: row.platform,
								},
							];
						}
          });
          setPackages(result);
        }
      });
  };

  return (
		<div className="setup-step">
			<span className="setup-num">{step}</span>
			<div className="setup-content">
				<h2>Set Runtime Hours / Month</h2>
				{(!selectedPackage || !componentClosed) && previousStepConfirmation ? (
					<Fragment>
						<ul className="packages-list">
							{packages.hasOwnProperty(country) && packages[country].length
								? packages[country].map((obj, i) => (
										<Fragment key={i}>
											{obj.platform === platform && (
												<li
													onClick={(e) => {
														setHours(obj.hours);
														setAmount(obj.price);
														setRuntime(obj.hours);
														setSelectedPackage(obj.id);
													}}
													key={i}
													className={obj.id === selectedPackage ? "active" : ""}
												>
													<div>
														<span className="hours">{obj.hours}</span>{" "}
														<span>hours</span>
													</div>
													<span className="price" style={{ display: 'none' }}>
														{CurrencyChar(country)} {obj.price}
													</span>
												</li>
											)}
										</Fragment>
								  ))
								: ""}
						</ul>
						{packageError ? <div className="error">{packageError}</div> : ""}
						<div className="instructions">
							{!isBusinessUser ? (
								<p className="faded">
									You can change this next month if you need.
									<br />
									You can also top up anytime in case you run out.
								</p>
							) : (
								<p className="faded">
									You can top up anytime in case you run out.
								</p>
							)}
						</div>
					</Fragment>
				) : (
					""
				)}

				{hours && componentClosed && previousStepConfirmation && (
					<div>
						{hours} hours{" "}
						<span onClick={handleChange} className="reopen">
							Change
						</span>
					</div>
				)}

				{!componentClosed && previousStepConfirmation && (
					<button
						className="btn btn-light"
						onClick={() => handleConfirmation()}
					>
						Confirm Runtime
					</button>
				)}
			</div>
		</div>
	);
};

export default SelectDuration;

export const machineInstanceStatus = {
  offline: "offline",
  online: "online",
  processing: "processing",
};

export const machineInstanceAPIResponse = {
  running: "RUNNING",
  wait: "WAIT",
  failed: "FAILED",
  stopped: "STOPPED",
};

export const singleUserMachineConfig = [
  {
    label: "Platform",
    value: "Unreal Engine",
  },
  {
    label: "CPU",
    value: "Intel Xeon E5-2670",
  },
  {
    label: "CPU Cores",
    value: 4,
  },
  {
    label: "RAM",
    value: "16 GB",
  },
  {
    label: "GPU",
    value: "Nvidia T4",
  },
  {
    label: "GPU RAM",
    value: "16 GB",
  },
];

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../store/auth-context";
import passwordImg from "../../images/icon-password.svg";
import { Bars } from "react-loader-spinner";
import ReCAPTCHA from "react-google-recaptcha";

const FormSignup = (props) => {
  const userName = useRef();
  const userEmail = useRef();
  const userPass = useRef();
  const userRePass = useRef();

  const [error, setError] = useState(null);
  const [createUser, setCreateUser] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");

  const showPassword = () => {
    setShowPass(!showPass);
  };

  const showRePassword = () => {
    setShowRePass(!showRePass);
  };

  const { signUp, addName } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      userName.current.value.trim() === "" ||
      userEmail.current.value.trim() === "" ||
      userPass.current.value.trim() === "" ||
      userRePass.current.value.trim() === ""
    ) {
      setError("All fields are required!");
      return;
    } else if (userPass.current.value !== userRePass.current.value) {
      setError("Password does not match!");
      return;
    }
    if (captchaValue === "") {
      setError("captcha verification is required");
    } else {
      setError("");
      setCreateUser(true);
      try {
        await signUp(userEmail.current.value, userPass.current.value);
        await addName(userName.current.value);
        //props.hidePopup();
        setCreateUser(false);
        localStorage.setItem("signup_username", userName.current.value);
        navigate("/welcome");
      } catch (error) {
        //console.log(error.code);
        setError("Something went wrong, please try again");
        if (error.code === "auth/weak-password")
          setError("Password should be at least 6 characters long");
        if (error.code === "auth/email-already-in-use")
          setError("The provided email is already in use by an existing user");
        if (error.code === "auth/invalid-email")
          setError("Email address is invalid");
        setCreateUser(false);
      }
    }
    // if (
    // 	/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(userEmail.current.value)
    // ) {
    // 	setError("Invalid Email Address");
    // 	return;
    // }
  };

  const handleCaptcha = (value) => {
    setCaptchaValue(value);
    value ? setError("") : setError("captcha verification is required");
  };

  return (
    <div>
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <input
            type="text"
            placeholder="Enter Your Name"
            name="Name"
            ref={userName}
            onChange={(e) => setError("")}
          />
        </div>
        <div className="form-row">
          <input
            type="email"
            placeholder="Enter Your Email Address"
            ref={userEmail}
            onChange={(e) => setError("")}
          />
        </div>
        <div className="form-row">
          <input
            type={showPass ? "text" : "password"}
            placeholder="Enter Your Password"
            ref={userPass}
            onChange={(e) => setError("")}
          />
          <img
            src={passwordImg}
            alt=""
            className="icon-password"
            onClick={showPassword}
          />
        </div>
        <div className="form-row">
          <input
            type={showRePass ? "text" : "password"}
            placeholder="Re-Enter Password"
            ref={userRePass}
            onChange={(e) => setError("")}
          />
          <img
            src={passwordImg}
            alt=""
            className="icon-password"
            onClick={showRePassword}
          />
        </div>
        <div className="form-row row-captcha">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleCaptcha}
          />
        </div>
        {error && <div className="form-row error">{error}</div>}
        <div className="form-row">
          <button type="submit" className="btn btn-dark">
            {createUser ? (
              <Bars color="#C4F34E" height={20} width={80} />
            ) : (
              "Sign Up"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormSignup;

import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label }) => {
	return (
		<Link
			className="mail-button"
			to="#"
			onClick={(e) => {
				e.preventDefault();
				window.location = mailto;
			}}
		>
			{label}
		</Link>
	);
};

export default ButtonMailto;

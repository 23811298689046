import { Fragment, useEffect } from "react";

const ConfigDetails = ({
  data,
  setConfigPopupShown,
  platform,
  selectedMachinePackId,
  isStatic,
}) => {
  return (
    <Fragment>
      {isStatic ? (
        <ul className="config-details">
          {data &&
            data.map((row, i) => (
              <li className="row" key={i}>
                <span className="col">{row.label}</span>
                <span className="col">
                  {row.label === "Platform" ? platform : row.value}
                </span>
              </li>
            ))}
        </ul>
      ) : (
        <>
          {data &&
            data.map((row, i) => (
              <>
                {selectedMachinePackId == row.packId && (
                  <ul className="config-details">
                    <li className="row">
                      <span className="col">Platform</span>
                      <span className="col">{row.platform}</span>
                    </li>
                    <li className="row" style={{display: "none"}}>
                      <span className="col">CPU</span>
                      <span className="col">{row.cpu}</span>
                    </li>
                    <li className="row">
                      <span className="col">CPU Cores</span>
                      <span className="col">{row.cores}</span>
                    </li>
                    <li className="row">
                      <span className="col">RAM</span>
                      <span className="col">{row.ram}</span>
                    </li>
                    <li className="row" style={{display: "none"}}>
                      <span className="col">GPU</span>
                      <span className="col">{row.gpu}</span>
                    </li>
                    <li className="row" style={{display: "none"}}>
                      <span className="col">GPU RAM</span>
                      <span className="col">{row.gpu_ram}</span>
                    </li>
                  </ul>
                )}
              </>
            ))}
        </>
      )}

      <button
        className="btn btn-dark"
        onClick={() => setConfigPopupShown(false)}
      >
        Done
      </button>
    </Fragment>
  );
};

export default ConfigDetails;

export const IconUnreal = () => {
	return (
		<svg
			width="48"
			height="44"
			viewBox="0 0 48 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M48 27.6459C44.2781 31.676 41.9197 32.6264 40.5194 32.5884C39.2665 32.5884 38.7875 31.828 38.7875 31.828C38.7138 31.4859 38.6032 26.4293 38.5664 21.4107C38.5295 16.202 38.5664 10.9553 38.8243 10.9553C40.2615 8.17993 44.8309 2.62909 44.8309 2.62909C44.8309 2.62909 44.8309 2.62909 44.794 2.62909C36.5028 4.30195 31.8597 9.73873 31.5649 10.0809C30.5331 9.24448 28.6537 9.16844 27.8062 9.16844C27.5114 9.16844 27.3271 9.16844 27.3271 9.16844C28.6537 9.92883 29.8698 12.0579 29.8698 13.8448V31.4478C29.8698 31.4478 27.0692 34.0332 24.8582 34.0332C23.5684 34.0332 22.8683 33.3108 22.4261 32.7025C22.1313 32.2843 22.0576 31.9421 22.0576 31.9421V10.233C21.5417 10.4231 21.3206 10.6132 20.9152 10.6132C20.4362 10.6132 19.9203 10.3851 19.5886 9.66269C19.3307 9.13041 19.1464 8.33201 19.1464 7.1534C19.1464 3.12334 23.5684 0.5 23.5684 0.5C23.5684 0.5 23.5684 0.5 23.5316 0.5C17.8935 2.0588 12.6977 5.02432 5.733 13.1605C-1.19481 21.2966 0.0949427 28.0641 0.0949427 28.0641C0.0949427 28.0641 2.01114 23.4257 6.6174 18.5592C8.79155 16.2401 10.413 15.4797 11.5185 15.4797C12.7714 15.4797 13.3241 16.4302 13.3241 17.2666V33.805C13.3241 35.4399 12.3292 35.7821 11.3711 35.7821C10.5604 35.7821 9.8602 35.4779 9.8602 35.4779H9.82335C14.5402 42.5115 25.89 43.5 25.89 43.5L30.8647 38.0632L30.9753 38.1773L35.5447 42.1693C42.583 37.9111 46.526 30.5354 47.5946 28.3683C47.8894 27.874 48 27.6459 48 27.6459Z"
				fill="black"
			/>
		</svg>
	);
}

export const IconUnity = () => {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M39.9116 38.4619L31.2928 23.9778L39.9116 9.49383L44.0665 23.9778L39.9116 38.4619ZM20.7294 37.0485L9.90039 26.3181H27.138L35.7567 40.8021L20.7294 37.0485ZM20.7294 10.8629L35.7129 7.10929L27.0941 21.5933H9.85651L20.7294 10.8629ZM42.7405 0L23.1601 5.07834L20.2429 10.068L14.3648 10.0237L0 23.9778L14.3648 37.932L20.2429 37.8876L23.1601 42.8773L42.7405 48L48 28.9675L45.0385 24.0222L48 19.0763L42.7405 0Z"
				fill="black"
			/>
		</svg>
	);
}

import { Link } from "react-router-dom";

const EmptyDashboard = () => {
	return (
		<div className="empty-dashboard">
			<p>Set up your Cloud Machine to get started</p>
			<Link to="/setup" className="btn btn-light">
				Configure your Set up
			</Link>
		</div>
	);
}

export default EmptyDashboard;
